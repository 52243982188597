
.print-only {
    display: none;
}

@media print
{
    body {
        print-color-adjust: exact;
    }

    /*@page {*/
    /*    margin: 0;*/
    /*}*/

    .not-in-print
    {
        display: none !important;
    }

    button
    {
        display: none !important;
    }

    .print-only {
        display: inline-block !important;
    }
}

